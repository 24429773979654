<template>
  <div class="container">
    <md-card class="dash-content">
      <div class="header-wrapper">
        <PageHeader :title="id ? 'Update Admin User' : 'Create New Role'" />
        <div>
          <md-button :to="{ name: 'Role Management' }" class="md-primary">
            Back
          </md-button>
        </div>
      </div>

      <div>
        <form novalidate class="md-layout" @submit.prevent="validateRole">
          <md-field :class="getValidationClass('display_name')">
            <label>Display Name (Human Readable)</label>
            <md-input v-model="form.display_name" required></md-input>
            <span class="md-error" v-if="!$v.form.display_name.required"
              >Display name is required</span
            >
          </md-field>

          <md-field :class="getValidationClass('description')">
            <label>Description</label>
            <md-textarea v-model="form.description" required></md-textarea>
            <span class="md-error" v-if="!$v.form.description.required"
              >Description is required</span
            >
          </md-field>

          <div class="card-body">
            <h4>Permissions</h4>
            <div class="form-check" style="display: flex; align-items: center">
              <md-checkbox
                v-model="form.checked"
                :value="true"
                multiple
                @change="(e) => handleCheck(e)"
                id="check-all"
              >
              </md-checkbox>
              <label for="check-all"> Check All</label>
            </div>
            <div
              class="form-check"
              style="display: flex; align-items: center"
              v-for="permission in permissions"
              :key="permission.id"
            >
              <md-checkbox
                v-model="form.permissions"
                :value="permission.id"
                :id="'check-' + permission.id"
                multiple
              >
              </md-checkbox>
              <label :for="'check-' + permission.id" class="form-check-label"
                >{{ permission.display_name }}
                {{ permission.guard_name == "web" ? "(Web)" : "(Sanctum)" }}
              </label>
            </div>

            <mdc-button :loading="loading">{{
              id ? "Update Role" : "Create Role"
            }}</mdc-button>
          </div>
        </form>
      </div>
    </md-card>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength, email } from "vuelidate/lib/validators";
import { createNamespacedHelpers } from "vuex";
const { mapActions, mapGetters } = createNamespacedHelpers("user");

export default {
  mixins: [validationMixin],
  data: () => ({
    form: {
      permissions: [],
      guard_name: "web",
    },
    checked: "",
    loading: false,
    permissions: [],
  }),
  validations: {
    form: {
      display_name: {
        required,
      },
      description: {
        required,
      },
    },
  },
  methods: {
    ...mapActions(["createRole", "updateRole", "getFormData", "getRole"]),
    async validateRole() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        let data = { ...this.form };

        try {
          this.loading = true;
          let call = this.id ? this.updateRole(data) : this.createRole(data);
          let msg = this.id ? "Role Updated" : "Role Created";
          await call;
          this.toast("success", msg);
          this.$router.push({ name: "Role Management" });
          this.loading = false;
        } catch (err) {
          console.log(err);
          this.loading = false;
          this.toast("error", "Opps, Something went wrong ");
        }
      }
    },
    async getData() {
      const permissions = await this.getFormData();
      this.permissions = permissions;
    },
    async getRoleData() {
      const { permissions, role } = await this.getRole(this.id);
      this.permissions = permissions;
      this.form = {
        description: role.description,
        display_name: role.display_name,
        name: role.name,
        guard_name: "web",
        permissions: role.permissions,
        id: role.id,
      };
      this.form.permissions = role.permissions.map((p) => p.id);
    },
    handleCheck(e) {
      if (e) {
        this.form.permissions = this.permissions.map((p) => p.id);
      } else {
        this.form.permissions = [];
      }
    },
  },
  mounted() {
    if (this.id) {
      this.getRoleData();
    } else {
      this.getData();
    }
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
  },
};
</script>

<style scoped lang="scss">
.feature-attributes {
  width: 100%;
  .wrapper {
    display: flex;
    align-items: center;
    gap: 20px;

    .val {
      flex: 1;
    }

    .attr {
      width: 25%;
    }
  }
}
</style>
